import { http } from "./http"
/**
 * @description 登录
 * @return res
 */
export function username(data) {
    return http.post("/api/security/login/username", data)
}
/**
 * @description 工作进度管理获取
 * @return res
 */
export function project(params) {
    return http.get("/api/admin/detail/project", params)
}
/**
 * @description 工作进度管理保存
 * @return res
 */
export function addProject(data) {
    return http.post("/api/admin/add/project", data)
}
/**
 * @description 日列表查询
 * @return res
 */
export function CommonWorkVOList(data) {
    return http.post("/api/admin/list", data)
}
/**
 * @description 月列表查询
 * @return res
 */
export function monthWorkVOList(data) {
    return http.post("/api/admin/list/month", data)
}
/**
 * @description 日工作添加
 * @return res
 */
export function addDailyWork(data) {
    return http.post("/api/admin/day/add", data)
}
/**
 * @description 日工作量修改
 * @return res
 */
export function updateDailyWork(data) {
    return http.post("/api/admin/update", data)
}
// 删除日工作数据
export function delDailyWork(id) {
    return http.delete("/api/admin/delete/day", id);
}
/**
 * @description 月工作添加
 * @return res
 */
export function addMonthWork(data) {
    return http.post("/api/admin/add/month", data)
}
/**
 * @description 月工作修改
 * @return res
 */
export function updateMonthWork(data) {
    return http.post("/api/admin/detail/month", data)
}
// 删除月工作数据
export function delMonthWork(id) {
    return http.delete("/api/admin/delete/month", id);
}
/**
 * @description 添加一周的数据
 * @return res
 */
export function addweekWork(data) {
    return http.post("/api/admin/add/week", data)
}
/**
 * @description 获取周的数据
 * @return res
 */
export function getweekWork(data) {
    return http.post("/api/admin/detail/week", data)
}
/**
 * @description 前台获取工程进度
 * @return res
 */
export function projectVO() {
    return http.get("/api/front/project")
}
/**
 * @description 前台获取天气
 * @return res
 */
export function queryTianQi() {
    return http.get("/api/front/weather/query")
}
/**
 * @description 前台获取周或月数据
 * @return res
 */
export function listWorkInWeekOrMonth(data) {
    return http.post("/api/front/list/month", data)
}
/**
 * @description 前台获取周或月数据
 * @return res
 */
export function listWorkInDayOrMonth(data) {
    return http.post("/api/front/list/day", data)
}
