/*
 * @Author: chenz
 * @Date: 2021-09-22 18:31:41
 * @LastEditors: chenz
 * @LastEditTime: 2022-08-31 14:29:58
 * @Description: 封装的axios
 * @FilePath: \blysClient\src\api\request.js
 */
import axios from "axios";
// import qs from 'qs';
import router from "@/router";
import store from "@/store";
import { Message } from "element-ui";
// 环境的切换
if (process.env.NODE_ENV == "development") {
  // axios.defaults.baseURL = "http://192.168.6.241:9994"; // 彭顺
  axios.defaults.baseURL = "http://192.168.6.232:8886"; // 乔驰
  // axios.defaults.baseURL = "http://192.168.6.27:9994"; // 国靖
  // axios.defaults.baseURL = "https://imedbooks.com:9994";
} else if (process.env.NODE_ENV == "production") {
  // axios.defaults.baseURL = "http://www.imedbooks.com"
  // axios.defaults.baseURL = "https://imedbooks.com:9994";
  axios.defaults.baseURL = "http://127.0.0.1:8886";
} else if (process.env.NODE_ENV == "test") {
  axios.defaults.baseURL = "https://www.ceshi.com";
}

axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
  // console.log("拦截器---", config);

  // 请求头
  config.headers = {
    // 可自定义内容类型
    "Content-Type": config["Content-type"] || "application/json",
    "token": localStorage.getItem("userToken"),
  };

  config.transformRequest = (data) => {
    return JSON.stringify(data);
  };

  // 请求添加时间戳避免缓存
  if (config.method === "post") {
    config.data = {
      _t: Date.parse(new Date()) / 1000,
      ...config.data,
    };
  }

  return config;
});

let isToken = false;
axios.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    console.log("axios.interceptors.response---error", err.response.status);
    if (err.response.status >= 500) {
      // Message.warning("服务端出错");
      // 根据响应码来处理提示语或者跳转等
      switch (err.response.data.code) {
        case 404:
          Message.warning('请求错误,未找到该资源');
          break;
        case 505:
        case 500:
        case 503:
          break;
        case 808: // token失效
          if (!isToken) {
            const token = localStorage.getItem("userToken");

            if (token) {
              isToken = true;
              localStorage.removeItem("userToken");
              Message.warning('登陆过期，自动返回首页，请重新登录');
              router.push("/");
            }
            store.commit("changeLoginDialog", true);
          }
          break;
      }
      return Promise.resolve(err.response);
    } else if (err.response.status != 200) {
      Message.warning('连接服务器失败');
      return Promise.resolve(err.response);
    } else {
      if (err && err.response) {
        // 根据响应码来处理提示语或者跳转等
        switch (err.response.data.code) {
          case 404:
            // Message.warning("请求错误,未找到该资源");
            break;
          case 505:
          case 500:
          case 503:
            // Message.warning("服务器端出错");
            break;
          case 808: // token失效

            if (!isToken) {
              const token = localStorage.getItem("userToken");

              if (token) {
                isToken = true;
                localStorage.removeItem("userToken");
                Message.warning('登陆过期，自动返回首页，请重新登录');
                console.log(router);
              }
              store.commit("changeLoginDialog", true);
            }
            break;
        }
      } else {
        Message.warning('连接服务器失败');
      }
      return Promise.resolve(err.response);
    }
  }
);
export default axios;
