/*
 * @Author: chenz
 * @Date: 2021-09-22 18:31:41
 * @LastEditors: chenz
 * @LastEditTime: 2022-03-04 10:01:09
 * @Description: Do not edit
 * @FilePath: \blysClient\src\api\http.js
 */
import axios from "@/api/request";
const http = {
  /**
   * @description get请求
   *  @param {String} url 请求地址
   *  @param {Object} params 请求参数
   * @return response data
   */
  get: (url, params) => {
    const config = {
      method: "get",
      url: url,
    };
    if (params) config.params = params;
    return axios(config);
  },

  /**
   * @description post请求
   *  @param {String} url 请求地址
   *  @param {Object} data 参数
   * @return 没有返回信息写 void / 有返回信息 {返回类型} 描述信息
   */
  post(url, data, ContentType, dataType) {
    // post(url, data, headers) {
    const config = {
      method: "post",
      url: url,
      "Content-type": ContentType || "application/json",
      dataType: dataType || "json",
    };
    if (data) config.data = data;
    return axios(config);
  },

  /**
   * @description delete请求
   *  @param {String} url 请求地址
   *  @param {String} id 选择的id
   * @return 没有返回信息写 void / 有返回信息 {返回类型} 描述信息
   */
  delete(url, id) {
    const config = {
      method: "delete",
      url: url + "?id=" + id,
    };
    return axios(config);
  },

  /**
   * @description put请求
   *  @param {String} url 请求地址
   *  @param {Object} data 当前选中的数据
   * @return 没有返回信息写 void / 有返回信息 {返回类型} 描述信息
   */
  put(url, data) {
    const config = {
      method: "put",
      url: url,
      data,
    };
    return axios(config);
  },

  /**
   * 下载文件 用于excel导出
   * @param url
   * @param parameter
   * @returns {*}
   */ downFile(url, parameter, method = "get") {
    if (method == "get") {
      return axios({
        url: url,
        params: parameter,
        method: method,
        responseType: "blob",
      });
    } else {
      return axios({
        url: url,
        method: method,
        data: parameter,
        responseType: "blob",
      });
    }
  },

  /**
   * 下载文件
   * @param url 文件路径
   * @param fileName 文件名
   * @param parameter
   * @returns {*}
   */
  downloadFile(url, fileName, parameter) {
    return http.downFile(url, parameter, "post").then(async (data) => {
      // const TEXT = await (new Response(data)).text()
      // if (TEXT) {
      //   const t = String(TEXT).split(',').map(i => {
      //     if (i.includes('message')) return i.split(':').slice(1, i.split(':').length).join('')
      //   }).filter(i => Boolean(i))
      //   Vue.prototype["$message"].warning(t[0]);
      // }
      if (data && data.type == "application/json") {
        // Vue.prototype["$message"].warning("导出次数不足!!!");
        return false;
      }
      if (typeof window.navigator.msSaveBlob !== "undefined") {
        window.navigator.msSaveBlob(new Blob([data]), fileName);
        return true;
      } else {
        let url = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); //下载完成移除元素
        window.URL.revokeObjectURL(url); //释放掉blob对象
        return true;
      }
    });
  },
};

export { http }; 
