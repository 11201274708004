import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { Message } from "element-ui";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: HomeView
  },
  {
    path: '/homePage',
    name: 'homePage',
    component: () => import('../views/homePage.vue'),
    children: [
      {
        path: "/home-page/data",
        name: "data",
        title: '工作进度管理',
        component: () =>
          import(
            "../components/myCenter/workProgress.vue"
          ),
      },
      {
        path: "/home-page/dailyWork",
        name: "dailyWork",
        title: '每日工作进度',
        component: () =>
          import(
            "../components/myCenter/dailyWork.vue"
          ),
      },
      {
        path: "/home-page/weeklyWork",
        name: "weeklyWork",
        title: '月工作进度',
        component: () =>
          import(
            "../components/myCenter/weeklyWork.vue"
          ),
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const baiRouterName = ['login']
router.beforeEach((to, from, next) => {
  const isToken = localStorage.getItem("userToken");
  if (isToken) {
    next()
  } else {
    if (!baiRouterName.some(i => i == to.name)) {
      next({ path: '/' })
      Message.warning('请先登录')
    } else {
      next()
    }
  }
})

export default router
