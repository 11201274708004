<template>
  <div class="animsition">
    <div class="login-container">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="账号" prop="username">
          <el-input type="text" v-model="ruleForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">登录</el-button>
          <el-button @click="resetForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="title">
      <a href="https://beian.miit.gov.cn/" target=_blank style="color: #fff;">鲁ICP备2023022030号</a>
    </div>
  </div>
</template>
<script>
import { username } from "../api/apis";
export default {
  data() {
    // var validatePass = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error("请输入密码"));
    //   }
    // };
    return {
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ required: true, message: "请输入账号" }],
        password: [{ required: true, message: "请输入密码" }],
      },
    };
  },
  methods: {
    async submitForm() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          const res = await username(this.ruleForm);
          if (res.code == 200) {
            localStorage.setItem("userToken", res.data.token);
            this.$router.push("/homePage");
          } else {
            this.$message.warning(res.msg);
          }
        } else {
          console.log(this.ruleForm);
        }
      });
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
    },
  },
};
</script>
<style scoped lang='scss'>
.animsition {
  height: 7.5294rem;
  overflow: hidden;
  background-image: url("../assets/img/loginBg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .title {
    position: absolute;
    bottom: 40%;
    left: 0%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep .el-form-item__label {
    color: #000;
  }
}

.login-container {
  width: 300px;
  height: 200px;
  margin: 50px auto 0px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}
</style>
