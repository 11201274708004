import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Message } from "element-ui";

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.prototype.$message = Message;

//引入echarts
import * as echarts from "echarts";
//引入组件
Vue.prototype.$echarts = echarts;

import 'lib-flexible'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
